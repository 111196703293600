.lens{
position:absolute;
cursor:crosshair;
display:block;
top:-500px;
left:0;
z-index:0;
border-radius:50%;
-webkit-box-shadow:inset 1px 1px 3px 1px #969696;
box-shadow:inset 1px 1px 3px 1px #969696;
background-repeat:no-repeat !important;
background-color:#fff;
}



.fade{
background-color:#fff;
opacity:0.3 !important;
}
img.tiksluslens{
z-index:0;
}