.container-fluid{
    padding-left: 0rem;
    padding-right: 0rem;
    overflow: hidden;
  }

  .navbar-toggler {
    padding: 1rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: left;
}

.sticky-top {
  position:fixed;
  top:0;
  width: 100%;
  z-index: 99999999;
}

.aNews {
  color: #593D00;
  text-decoration: none;
  background-color: transparent;
}

.img-thumbnail2 {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
}

.modal2 {
  font-size: 12px;
}
.modal2 > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal2 > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal2 > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal2 > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgb(243, 213, 147);
  border-bottom: 1px solid rgba(0,0,0,.125);
  color: rgb(110, 91, 3);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link{
  color: rgb(110, 91, 3);
}

.text-link {  
  color: #adc0d3;
  font-size: 20px;
  font-weight: 500;
}


.text-sanctuary {  
  color: #927f26;
  font-size: 20px;
  font-weight: 500;  
}